import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import PDFViewer from './components/PDFViewer';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/:parametro" element={<PDFViewer />} />
      </Routes>
    </Router>
  );
}

export default App;
